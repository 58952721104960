import axios from 'axios';
import { API_BASE_URL, API_BASE_URL_ON_PREM, API_BASE_URL_AUTH } from './config';
import { getAuthToken } from "../utils/auth";
import { store } from '../store';
import { showModal } from '../features/modal/modalSlice';
import { showNotification } from '../features/notification/notificationSlice';


const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;

      const msalAccessToken = localStorage.getItem('msalaccesstoken');
      if (msalAccessToken) {
        config.headers['msalaccesstoken'] = msalAccessToken;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(showModal({
        title: 'Session Expired',
        message: 'Your session has expired. Please log in again.',
      }));
    }
    else if (error.response && error.response.status === 500) {
      store.dispatch(showNotification({ message: error.response.data.errorMessage || 'There is some problem in this operation.Please check with administrator.', type: 'error' }));
    }
    else if (error?.response?.data) {
      const errorMessage = error?.response?.data?.errorMessage || error?.response?.data;
      if (typeof errorMessage === 'string') {
        store.dispatch(showNotification({ message: errorMessage || 'An unknown error occurred', type: 'error' }));
      }
    }
    return Promise.reject(error);
  }
);

export const updateBaseUrl = (apiType: number) => {
  axiosInstance.defaults.baseURL = apiType === 1 ? API_BASE_URL_ON_PREM : apiType === 2 ? API_BASE_URL_AUTH : API_BASE_URL;
};

export const handleAxiosError = (error: unknown): string => {
  if (axios.isAxiosError(error) && error.response) {
    if (typeof error.response.data === 'string') {
      return error.response.data;
    }
    if (typeof error.response.data === 'object' && error.response.data !== null) {
      const message = (error.response.data as { message?: string }).message;
      if (message) {
        return message;
      }
    }
  }
  return 'An unknown error occurred';
};

export default axiosInstance;
